import GatsbyImage from "gatsby-image";
import React from "react";
import { useHeroImage } from "../../Home/Hero/hooks/useHeroImage";
import { Container } from "../Container/Container";
import { SectionHeader } from "../SectionHeader/SectionHeader";
import { MessageForm } from "./components/MessageForm";

export interface MessageFormSectionProps {
  className?: string;
}

export const MessageFormSection = (props: MessageFormSectionProps) => {
  const { className = "" } = props;
  const image = useHeroImage();
  return (
    <section id="kontakt-form" className={`${className}`}>
      <Container className="relative">
        <div className="center w-full lg:w-3/5 mx-auto z-10">
          <SectionHeader
            title={"POTREBUJTE PREDAŤ ALEBO KÚPIŤ NEHNUTEĽNOSŤ?"}
            subtitle="Kontaktujte ma"
            description="Máte záujem o nezáväznú konzultáciu? "
            className="text-center"
          />
          <div
            data-sal="slide-up"
            data-sal-delay={200}
            data-sal-easing="ease"
            data-sal-duration="800"
            className="form py-16"
          >
            <MessageForm />
          </div>
        </div>

        <div
          data-sal="slide-left"
          data-sal-delay={500}
          data-sal-easing="ease"
          data-sal-duration="800"
          className="image w-80 absolute -right-20 bottom-0 z-0 hidden 2xl:block"
        >
          <GatsbyImage fluid={image} />
        </div>
      </Container>
    </section>
  );
};
