import { useToast } from "@chakra-ui/toast";
import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { validationMessages } from "../../../../lib/consts";
import { useSubmitMessageForm } from "../../../../lib/features/form/message/hooks/useCreateValuationSubmissionMutation";
import { MessageFormSubmissionInput } from "../../../../lib/features/form/message/model/message-form.model";
import { useContactInfo } from "../../../../lib/hooks/useContactInfo";
import { Button } from "../../Button/Button";
import { AgreementCheckbox } from "../../Form/Inputs/AgreementCheckbox";
import { TextAreaInput } from "../../Form/Inputs/TextAreaInput";
import { TextInput } from "../../Form/Inputs/TextInput";

export interface MessageFormProps {
  className?: string;
}

export const MessageForm = (props: MessageFormProps) => {
  const { className = "" } = props;
  const { phone, email, address } = useContactInfo();
  const form = useForm({
    mode: "onBlur",
    defaultValues: {
      agreement: false,
    },
  });
  const toast = useToast();

  const { mutate, isLoading } = useSubmitMessageForm({
    onSuccess: () => {
      form.reset({});
      toast({
        title: "Form odoslaný",
        status: "success",
        position: "top",
        duration: 9000,
        isClosable: true,
      });
    },
    onError: () => {
      toast({
        title: "Form sa nepodarilo odoslať",
        status: "error",
        position: "top",
        duration: 9000,
        isClosable: true,
      });
    },
  });

  const onSubmit = async (data: MessageFormSubmissionInput) => {
    mutate(data);
  };

  const {
    formState: { errors },
  } = form;

  return (
    <FormProvider {...form}>
      <form className={`${className}`} onSubmit={form.handleSubmit(onSubmit)}>
        <div className="fields grid grid-cols-2 gap-x-10 gap-y-6">
          <TextInput
            className="col-span-2 sm:col-span-1"
            name="nameAndSurname"
            label="Meno a priezvisko"
            placeholder="meno a priezvisko"
            options={{
              required: {
                value: true,
                message: validationMessages.fieldRequired,
              },
            }}
          />
          <TextInput
            className="col-span-2 sm:col-span-1"
            name="phone"
            label="Telefonický kontakt"
            placeholder="telefonický kontakt"
            options={{
              required: {
                value: true,
                message: validationMessages.fieldRequired,
              },
            }}
          />
          <TextInput
            name="email"
            label="Email"
            placeholder="e-mail"
            className="col-span-2"
            options={{
              pattern: {
                value: /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/,
                message: validationMessages.email,
              },
              required: {
                value: true,
                message: validationMessages.fieldRequired,
              },
            }}
          />
          <TextInput
            className="col-span-2"
            name="subject"
            label="Predmet Vašej správy"
            placeholder="predmet Vašej správy"
            options={{
              required: {
                value: true,
                message: validationMessages.fieldRequired,
              },
            }}
          />
          <TextAreaInput
            className="col-span-2"
            name="message"
            label="Vaša správa"
            placeholder="obsah Vašej správy"
            options={{
              required: {
                value: true,
                message: validationMessages.fieldRequired,
              },
            }}
          />
          <div className="col-span-2">
            <AgreementCheckbox />
          </div>
        </div>

        <div className="actions mt-12 flex lg:space-x-8 items-center flex-col lg:flex-row">
          <Button
            isLoading={isLoading}
            type="submit"
            w={{
              base: "full",
              lg: "auto",
            }}
          >
            POSLAŤ SPRÁVU
          </Button>
          <div className="callme flex lg:space-x-4 text-2xl font-medium flex-col lg:flex-row mt-6 lg:mt-0">
            <span>Alebo mi zavolajte:</span>
            <a
              className="font-bold text-primary hover:opacity-70 transition-all duration-200"
              href={`tel:${phone}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {phone}
            </a>
          </div>
        </div>
      </form>
    </FormProvider>
  );
};
