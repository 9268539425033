import React from "react";
import { Container } from "../../Shared/Container/Container";

export interface BlogHeaderProps extends Article.Article {
  className?: string;
}

export const InfoItem = ({ label = "", value = "" }) => (
  <div className="space-x-2  text-lg flex">
    <div className="labe text-primary font-medium">{label}:</div>
    <div className="value font-medium text-secondary">{value}</div>
  </div>
);

export const BlogHeader = (props: BlogHeaderProps) => {
  const { className = "", title, author, date, topic } = props;
  return (
    <section className={`${className} pt-10 lg:pt-16`}>
      <Container>
        <h1 className="text-3xl lg:text-5xl uppercase font-medium text-secondary">
          {title}
        </h1>
        <div className="info mt-8 flex lg:space-x-10 flex-col lg:flex-row space-y-4 lg:space-y-0">
          <InfoItem label="Author" value={author.fullName} />
          <InfoItem
            label="Dátum"
            value={new Date(date).toLocaleDateString("sk")}
          />
          <InfoItem label="Téma" value={topic} />
        </div>
      </Container>
    </section>
  );
};
