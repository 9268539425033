import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Textarea,
} from "@chakra-ui/react";
import React from "react";
import { useFormContext } from "react-hook-form";
import { TextInputProps } from "./TextInput";

export interface TextAreaInputProps extends TextInputProps {
  className?: string;
  rows?: number;
}

export const TextAreaInput = (props: TextAreaInputProps) => {
  const {
    className = "",
    label,
    rows = 5,
    name,
    placeholder,
    options = {},
  } = props;

  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <FormControl
      isRequired={!!options?.required || !!options?.pattern}
      className={className}
      isInvalid={errors[name]}
    >
      <FormLabel fontWeight="medium" htmlFor={name}>
        {label}
      </FormLabel>
      <Textarea
        rows={rows}
        shadow="lg"
        rounded="sm"
        py="4"
        px="6"
        _focus={{
          shadow: "xl",
        }}
        variant="unstyled"
        placeholder={placeholder}
        {...register(name, options)}
      />
      <FormErrorMessage color="red.300">
        {errors[name] && errors[name].message}
      </FormErrorMessage>
    </FormControl>
  );
};
