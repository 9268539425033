import { gql } from "graphql-request";

export const MESSAGE_FORM_SUBMISSION_MUTATION = gql`
mutation submitForm(
  $email: String,
  $phone: String,
  $nameAndSurname: String,
  $subject: String,
  $message: String,
  $agreement: Boolean,
) {
  createFormSubmission(input: {data: {
    type: message,
    formData: [
      {
        __typename: "ComponentFormsMessageForm",
        nameAndSurname: $nameAndSurname,
        email: $email,
        phone: $phone,
        subject: $subject,
        message: $message,
        agreement: $agreement
      }
    ]
  }}) {
    formSubmission {
      id
    }
  }
}
`