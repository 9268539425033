import { graphql, useStaticQuery } from "gatsby";

export const useArticles = (): Article.Article[] => {
  const {
    allStrapiArticle: { nodes },
  } = useStaticQuery(graphql`
    query useArticles {
      allStrapiArticle(sort: {fields: date, order: DESC}) {
        nodes {
          content
          excerpt
          date
          image {
            childImageSharp {
              gatsbyImageData
            }
          }
          topic
          title
          slug
          author {
            fullName
          }
        }
      }
    }
  `);
  return nodes;
};
