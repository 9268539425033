import React, { useMemo, useState } from "react";
import { Button } from "../../Shared/Button/Button";
import { Container } from "../../Shared/Container/Container";
import { SectionHeader } from "../../Shared/SectionHeader/SectionHeader";
import { ArticleListItem } from "./components/ArticleListItem";
import { useArticles } from "./hooks/useArticles";

const MAX_DISPLAY_ARTICLES = 6;

export interface ArticleListProps {
  className?: string;
}

export const ArticleList = (props: ArticleListProps) => {
  const { className = "" } = props;
  const articles = useArticles();
  const [showingAll, setshowingAll] = useState(false);

  const displayArticles = useMemo(
    () => (!showingAll ? articles.slice(0, MAX_DISPLAY_ARTICLES) : articles),
    [showingAll, articles]
  );

  return (
    <section className={`${className} py-16`}>
      <Container>
        <SectionHeader
          className="lg:w-1/2"
          subtitle="Realitný  magazín"
          title="PRINÁŠAM VÁM NOVINKY ZO SVETA REALÍT"
        />
        <div className="article-cards py-16 flex flex-col space-y-8">
          {displayArticles.map((article) => (
            <ArticleListItem key={article.slug} {...article} />
          ))}
        </div>
        <div className="showall flex justify-center">
          <Button
            hidden={showingAll || articles.length < MAX_DISPLAY_ARTICLES}
            onClick={() => setshowingAll(true)}
          >
            NAČÍTAŤ VIAC ČLÁNKOV
          </Button>
        </div>
      </Container>
    </section>
  );
};
