import { Link } from "gatsby";
import React from "react";
import { generateBlogRoute } from "../../../../lib/routes";
import { Button } from "../../../Shared/Button/Button";
import { ContentRenderer } from "../../../Shared/ContentRenderer/ContentRenderer";
import { ArticleImageThumbnail } from "../../ArticleImageThumbnail/ArticleImageThumbnail";
import { InfoItem } from "../../BlogHeader/BlogHeader";

export interface ArticleListItemProps extends Article.Article {
  className?: string;
}

export const ArticleListItem = (props: ArticleListItemProps) => {
  const { className = "", topic, slug, excerpt } = props;

  return (
    <article className={`${className} flex flex-col lg:flex-row lg:space-x-8`}>
      <div className="image">
        <ArticleImageThumbnail {...props} />
      </div>
      <div className="text-info flex flex-col justify-between py-4">
        <div className="topic flex lg:hidden mb-4">
          <InfoItem label="Téma" value={topic} />
        </div>
        <ContentRenderer className="text-lg" source={excerpt} />
        <div className="bottom flex lg:space-x-8 items-center mt-4 flex-col lg:flex-row">
          <Link to={generateBlogRoute(slug)} className="">
            <Button
              _hover={{
                color: "#D9B954",
              }}
              bg="#20364A"
            >
              OTVORIŤ ČLÁNOK
            </Button>
          </Link>
          <div className="topic hidden lg:flex">
            <InfoItem label="Téma" value={topic} />
          </div>
        </div>
      </div>
    </article>
  );
};
