import React from "react";
import { ArticleList } from "../../components/Blog/ArticleList/ArticleList";
import Layout from "../../components/Shared/Layout/Layout";
import { MessageFormSection } from "../../components/Shared/MessageFormSection/MessageFormSection";

export interface BlogPageProps {
  className?: string;
}

export const BlogPage = (props: BlogPageProps) => {
  const { className = "" } = props;
  return (
    <Layout className={`${className}`}>
      <ArticleList />
      <MessageFormSection className="mt-10" />
    </Layout>
  );
};

export default BlogPage;
