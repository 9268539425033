import React from "react";
import { Box } from "@chakra-ui/layout";
import BackgroundImage from "gatsby-background-image";
import { convertToBgImage } from "gbimage-bridge";
import { getImage } from "gatsby-plugin-image";
import { generateBlogRoute } from "../../../lib/routes";
import { Link } from "gatsby";

export interface ArticleImageThumbnailProps extends Article.Article {
  className?: string;
}

export const ArticleImageThumbnail = (props: ArticleImageThumbnailProps) => {
  const {
    className = "",
    image,
    title,
    topic,
    author,
    date,
    content,
    slug,
    excerpt,
  } = props;

  const bgImage = convertToBgImage(getImage(image));

  return (
    <Link to={generateBlogRoute(slug)} className={`${className}`}>
      <BackgroundImage {...bgImage}>
        <Box
          className="hover:shadow-2xl transition-all duration-200 group shadow-xl"
          width={{
            base: "auto",
            lg: 380,
          }}
          height={{
            base: 300,
            lg: 380,
          }}
          position="relative"
          shadow="dark-lg"
        >
          <div className="overlay bg-black opacity-70 group-hover:opacity-50 transition-all duration-200 absolute h-full w-full"></div>
          <div className="content p-6 relative z-10 text-white flex justify-end flex-col h-full">
            <h2 className="font-medium text-2xl">{title}</h2>
            <div className="author mt-5">
              <span>Author:</span>
              <span className="ml-2 font-medium">{author.fullName}</span>
            </div>
            <div className="dat mt-2 flex justify-between">
              <div>{new Date(date).toLocaleDateString("sk")}</div>
              <div className="underline text-lg font-medium">
                Otvoriť článok
              </div>
            </div>
          </div>
        </Box>
      </BackgroundImage>
    </Link>
  );
};
