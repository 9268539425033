import { useMutation, UseMutationOptions } from "react-query";
import { submitMessageForm } from "../api/message-form.api";
import {
    MessageFormSubmissionInput,
    MessageFormSubmissionReturn
} from "../model/message-form.model";

export const useSubmitMessageForm = (
  options: UseMutationOptions<
    MessageFormSubmissionReturn,
    null,
    MessageFormSubmissionInput
  > = {}
) => {
  return useMutation<
    MessageFormSubmissionReturn,
    null,
    MessageFormSubmissionInput
  >(submitMessageForm, {
    ...options,
  });
};
